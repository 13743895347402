<template>
  <component
    :is="item.isExternal ? 'a' : 'router-link'"
    :href="item.url.cached_url"
    :to="localizedRoute(item.url.cached_url)"
    :target="item.target"
    class="md:flex text-h5 text-white font-bold uppercase leading-loose tracking-xl py-4 md:py-0 md:px-7 items-center hover:bg-grey-30 link"
  >
    {{ item.name }}
  </component>
</template>

<script>
import {
  Blok
} from 'src/modules/vsf-storyblok-module/components'
export default {
  extends: Blok,
  name: 'HeaderMenuLink'
}

</script>

<style lang="scss" scoped>
$screen-md: 768px;

@media screen and (max-width: #{$screen-md - 1}) {
  .link {
    @apply font-normal text-average leading-h6 tracking-lg block;
  }
}
</style>
